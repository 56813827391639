module.exports = [
  {
    locale: "fi",
    label: "Suomi",
    default: true,
  },
  {
    locale: "en",
    label: "English",
  },
]
