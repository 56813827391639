import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { IntlProvider, addLocaleData } from "react-intl"

import PageContext from "./PageContext"

import fiData from "react-intl/locale-data/fi"
import enData from "react-intl/locale-data/en"
import { languages } from "../i18n"

import Header from "../components/Header"
import Gallery from "../components/Gallery"
import Footer from "../components/Footer"
import SEO from "../components/SEO"

import "../css/bulma.scss"

import flatten from "flat"

addLocaleData([...fiData, ...enData])

const withLayout = (customProps) => (PageComponent) => (props) => {
  const { locale } = props.pageContext
  const { localeKey, hideLangs } = customProps

  const pageContextValue = { custom: customProps, page: props.pageContext }

  const defaultLocale = languages.find((language) => language.default).locale
  const pageLocale = locale || defaultLocale

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleandTranslations {
          site {
            siteMetadata {
              title
            }
          }
          allContentfulTuotteetJaPalvelut {
            nodes {
              ProductsandServices_Title
              ProductsandServices_Introduction {
                ProductsandServices_Introduction
              }
              ProductsandServices_Examples
              ProductsandServices_Services {
                ProductsandServices_Services
              }
              ProductsandServices_TheresMore {
                ProductsandServices_TheresMore
              }
              ProductsandServices_ExtraServices {
                ProductsandServices_ExtraServices
              }
              node_locale
            }
          }
          allContentfulHome {
            nodes {
              Home_Cottage {
                Home_Cottage
              }
              Home_Introduction {
                Home_Introduction
              }
              Home_Lamb {
                Home_Lamb
              }
              Home_Island {
                Home_Island
              }
              node_locale
            }
          }
          allContentfulAuthorsAndContacts {
            nodes {
              AuthorsAndContacts_Contacts {
                AuthorsAndContacts_Contacts
              }
              AuthorsAndContacts_Main {
                AuthorsAndContacts_Main
              }
              AuthorsAndContacts_Title
              node_locale
            }
          }
          allContentfulIslandsEvents {
            nodes {
              IslandEvents_Title
              IslandEvents_Main {
                IslandEvents_Main
              }
              node_locale
            }
          }
          allContentfulEscapeGame {
            nodes {
              EscapeGame_Main {
                EscapeGame_Main
              }
              node_locale
            }
          }
          allContentfulGlobal {
            nodes {
              node_locale
              Global_AuthorsAndContact
              Global_FollowUs
              Global_Home
              Global_IslandsEvents
              Global_ReservationsandQueries
              Global_ProductsAndServices
              Global_EscapeGame
            }
          }
        }
      `}
      render={(data) => {
        const contentful = flattenQuery(data, pageLocale)

        const pageTitle = locale ? contentful[`${localeKey}_Title`] : ""

        return (
          <IntlProvider locale={pageLocale} messages={contentful}>
            <PageContext.Provider value={pageContextValue}>
              <SEO title={pageTitle} lang={pageLocale} />
              <Header
                siteTitle={data.site.siteMetadata.title}
                hideLangs={hideLangs}
              />
              <>
                <main>
                  <PageComponent {...props} />
                </main>
                <Gallery />
                <Footer />
              </>
            </PageContext.Provider>
          </IntlProvider>
        )
      }}
    />
  )
}

const flattenQuery = (data, pageLocale) => {
  let contentful = {}

  Object.assign(
    contentful,
    data.allContentfulHome.nodes.find((locale) =>
      locale.node_locale.includes(pageLocale)
    ),
    data.allContentfulTuotteetJaPalvelut.nodes.find((locale) =>
      locale.node_locale.includes(pageLocale)
    ),
    data.allContentfulAuthorsAndContacts.nodes.find((locale) =>
      locale.node_locale.includes(pageLocale)
    ),
    data.allContentfulIslandsEvents.nodes.find((locale) =>
      locale.node_locale.includes(pageLocale)
    ),
    data.allContentfulEscapeGame.nodes.find((locale) =>
      locale.node_locale.includes(pageLocale)
    ),
    data.allContentfulGlobal.nodes.find((locale) =>
      locale.node_locale.includes(pageLocale)
    )
  )

  flatten(contentful)

  for (let node in contentful) {
    ;({ [node]: node } = contentful)
    if (typeof node === "object") contentful = { ...contentful, ...node }
  }

  return contentful
}

withLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withLayout
