import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import Link from "../Link"
import Langs from "../Langs"
import { FormattedHTMLMessage } from "react-intl"
import LogoHeader from "../LogoHeader"
import { isMobile } from "react-device-detect"

const Header = ({ hideLangs }) => {
  const [activeMenu, setActiveMenu] = useState(false)
  const [activeFlags, setactiveFlags] = useState({
    scrollPos: 0,
    show: true,
  })

  const handleBurger = () => {
    setActiveMenu(!activeMenu)
  }

  const handleScroll = () => {
    const { scrollPos } = activeFlags
    setactiveFlags({
      scrollPos: document.body.getBoundingClientRect().top,
      show: document.body.getBoundingClientRect().top > scrollPos,
    })
  }

  useEffect(() => {
    if (isMobile) {
      window.addEventListener("scroll", handleScroll)
      return () => {
        window.removeEventListener("scroll", handleScroll)
      }
    }
  }, [handleScroll])

  return (
    <>
      <nav
        className={`navbar is-fixed-top`}
        role='navigation'
        aria-label='main navigation'
      >
        <div className='navbar-brand'>
          <Link className='logo' to='/'>
            <LogoHeader />
          </Link>
          <a
            role='button'
            className={`navbar-burger burger ${activeMenu ? "is-active" : ""}`}
            aria-label='menu'
            aria-expanded='false'
            data-target='navbar'
            onClick={handleBurger}
          >
            <span aria-hidden='true'></span>
            <span aria-hidden='true'></span>
            <span aria-hidden='true'></span>
          </a>
        </div>
        <div
          id='navbar'
          className={`navbar-menu ${activeMenu ? "is-active" : ""}`}
        >
          <div className='navbar-end'>
            <Link className='navbar-item has-text-white' to='/'>
              <FormattedHTMLMessage id='Global_Home' />
            </Link>
            <Link
              to='/tuotteet-ja-palvelut'
              className='navbar-item has-text-white'
            >
              <FormattedHTMLMessage id='Global_ProductsAndServices' />
            </Link>
            <Link
              className='navbar-item has-text-white'
              to='/saaressa-tapahtuu'
            >
              <FormattedHTMLMessage id='Global_IslandsEvents' />
            </Link>
            <Link className='has-text-white navbar-item' to='/pakopeli'>
              <FormattedHTMLMessage id='Global_EscapeGame' />
            </Link>
            <Link
              className='has-text-white navbar-item'
              to='/tekijat-ja-yhteystiedot'
            >
              <FormattedHTMLMessage id='Global_AuthorsAndContact' />
            </Link>
          </div>
        </div>
        <div
          className={`is-pulled-right navbar-item has-dropdown is-hoverable langs ${
            activeFlags.show ? "active" : "hidden"
          }`}
        >
          {!hideLangs && <Langs />}
        </div>
      </nav>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  hideLangs: PropTypes.bool,
}

Header.defaultProps = {
  siteTitle: ``,
  hideLangs: false,
}

export default Header
