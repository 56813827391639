import React from "react"
import LogoFooter from "../LogoFooter"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons"
import { FormattedHTMLMessage } from "react-intl"

const Footer = () => {
  return (
    <>
      <section className='section has-text-centered has-text-grey mt-15'>
        <div className='container'>
          <div className='footerlogo'>
            <LogoFooter />
          </div>
        </div>
        <div className='columns'>
          <div className='column'></div>
          <div className='column'>
            <p className='is-size-5 has-text-weight-bold'>
              <FormattedHTMLMessage id='Global_FollowUs' />
            </p>
            <div className='column'>
              <FontAwesomeIcon icon={faFacebook} />
              <a
                href='https://www.facebook.com/oskkotasaari/'
                rel='noopener'
                target='_blank'
              >
                {" "}
                @oskkotasaari
              </a>
            </div>
            <div className='column'>
              <FontAwesomeIcon icon={faInstagram} />
              <a
                href='https://www.instagram.com/oskkotasaari/'
                rel='noopener'
                target='_blank'
              >
                {" "}
                #oskkotasaari
              </a>
            </div>
          </div>
          <div className='column'>
            <p className='is-size-5 has-text-weight-bold'>
              <FormattedHTMLMessage id='Global_ReservationsandQueries' />
            </p>
            <div className='column'>
              <FontAwesomeIcon icon={faPhone} />
              +358 40 827 4530
            </div>
            <div className='column'>
              <FontAwesomeIcon icon={faEnvelope} />
              <a href='mailto:info@kotasaari.fi'> info@kotasaari.fi</a>
            </div>
          </div>
          <div className='column'></div>
        </div>
      </section>
      <div className='footer has-text-centered'>© Kotasaari 2020</div>
    </>
  )
}

export default Footer
