import React, { useContext } from "react"
import { navigate } from "gatsby"
import { injectIntl } from "react-intl"

import languages from "../i18n/languages"
import PageContext from "../layout/PageContext"

import ReactCountryFlag from "react-country-flag"

const buttonStyle = {
  paddingLeft: "16px",
  border: 0,
  cursor: "pointer",
  backgroundColor: "transparent",
}

const LangButton = ({ label, onClick, chosen, locale }) => (
  <>
    {!chosen ? (
      <button
        onClick={onClick}
        style={{
          ...buttonStyle,
        }}
      >
        <ReactCountryFlag
          countryCode={locale === "en" ? "gb" : locale}
          svg
          style={{
            width: "3em",
            height: "3em",
          }}
          title={locale === "en" ? "gb" : locale}
        />
      </button>
    ) : (
      <></>
    )}
  </>
)

const Langs = ({ intl: { locale } }) => {
  const pageContext = useContext(PageContext)

  const handleSetLang = (language) => {
    const { originalPath } = pageContext.page
    const newPathname = `/${language}${originalPath}`

    localStorage.setItem("language", language)
    navigate(newPathname)
  }
  if (!pageContext.custom.localeKey) return null
  return (
    <>
      <div className='navbar-link is-arrowless'>
        <ReactCountryFlag
          countryCode={locale === "en" ? "gb" : locale}
          svg
          style={{
            width: "3em",
            height: "3em",
          }}
          title={locale === "en" ? "gb" : locale}
        />
      </div>
      <div className='navbar-dropdown'>
        {languages.map((language) => (
          <LangButton
            key={language.locale}
            locale={language.locale}
            label={language.label}
            chosen={language.locale === locale}
            onClick={() => handleSetLang(language.locale)}
          />
        ))}
      </div>
    </>
  )
}

export default injectIntl(Langs)
