import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.app/gatsby-image
 * - `StaticQuery`: https://gatsby.app/staticquery
 */

const LogoFooter = () => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulLogos(filter: { node_locale: { eq: "fi" } }) {
          nodes {
            footer {
              localFile {
                childImageSharp {
                  fixed(width: 150, height: 150) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
                name
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <Img
        fixed={
          data.allContentfulLogos.nodes[0].footer.localFile.childImageSharp
            .fixed
        }
        alt={data.allContentfulLogos.nodes[0].footer.localFile.name
          .replace(/-/g, " ")
          .substring(2)}
      />
    )}
  />
)
export default LogoFooter
