import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import ReactIdSwiperCustom from "react-id-swiper/lib/ReactIdSwiper.custom"
import { Swiper, Autoplay } from "swiper/js/swiper.esm"

const Gallery = () => {
  const { allContentfulBottomCarousel } = useStaticQuery(
    graphql`
      query {
        allContentfulBottomCarousel(filter: { node_locale: { eq: "fi" } }) {
          edges {
            node {
              images {
                localFile {
                  name
                  childImageSharp {
                    fluid(maxWidth: 1000) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const params = {
    Swiper,
    modules: [Autoplay],
    slidesPerView: 4,
    spaceBetween: 0,
    grabCursor: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    breakpoints: {
      1024: {
        slidesPerView: 4,
      },
      768: {
        slidesPerView: 3,
      },
      640: {
        slidesPerView: 2,
      },
      320: {
        slidesPerView: 1,
      },
    },
    preloadImages: false,
    shouldSwiperUpdate: true,
  }

  for (
    let i = allContentfulBottomCarousel.edges[0].node.images.length - 1;
    i > 0;
    i--
  ) {
    const j = Math.floor(Math.random() * i)
    const temp = allContentfulBottomCarousel.edges[0].node.images[i]
    allContentfulBottomCarousel.edges[0].node.images[i] =
      allContentfulBottomCarousel.edges[0].node.images[j]
    allContentfulBottomCarousel.edges[0].node.images[j] = temp
  }

  return (
    <>
      <hr
        style={{
          width: "50px",
          margin: "auto",
          border: "1px solid rgba(59, 97, 43, 0.55)",
        }}
      />
      <div className='mt-30'>
        <ReactIdSwiperCustom {...params}>
          {allContentfulBottomCarousel.edges[0].node.images.map((value, i) => (
            <Img
              fluid={value.localFile.childImageSharp.fluid}
              key={i}
              alt={value.localFile.name.replace(/-/g, " ").substring(2)}
            />
          ))}
        </ReactIdSwiperCustom>
      </div>
    </>
  )
}

export default Gallery
